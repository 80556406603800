import React, { useState, useEffect } from 'react';
import { useCartContext } from '../Context/CartContext';
import Message from '../Message/Message';
import "../Checkout/checkout.css";
import sucursal from "../../Imagenes/sucursal.png";
import Delivery from "../../Imagenes/delivery.png";
import Cash from "../../Imagenes/cash.png";
import Tc from "../../Imagenes/credito.png";
import Home from "../../Imagenes/home.png";
import Icon from './Icon';
import axios from 'axios';

export const Checkout = ({ branches, onSubmit }) => {
  const { totalPrice } = useCartContext();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('Efectivo/transferencia');
  const [deliveryOption, setDeliveryOption] = useState('Retiro en sucursal');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [zona, setZona] = useState('Zona sucursal Patagones 305');
  const [formIsValid, setFormIsValid] = useState(false);
  const [data, setData] = useState([]);
  const [precioEnvio, setPrecioEnvio] = useState({});

  const fetchData = async () => {
    try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
        setData(response.data);
    } catch (error) {
        console.error('Error al obtener los teléfonos:', error);
    }
};

// Ejecutar la solicitud al montar el componente
useEffect(() => {
    fetchData();
}, []);

const obtenerEnvio = async () => {
  try {
      const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/precio-envio');
      if (response.data.length > 0) {
          setPrecioEnvio(response.data[0]); // Asumiendo que el primer objeto contiene el precio extra
          console.log("Precio envio configurado:", response.data[0].envio);

      } else {
          setPrecioEnvio({ envio: "No disponible" }); // Valor por defecto si no se encuentra
      }
      console.log("Datos de precio envio obtenidos correctamente:", response.data[0].envio);
  } catch (error) {
      console.error("Error al obtener precio evio:", error);

  }
};


useEffect(() => {
  obtenerEnvio();
},[]);



  // Verifica la validez del formulario cada vez que cambian los campos obligatorios
  useEffect(() => {
    const isValid = firstName.trim() !== '' && lastName.trim() !== '' && phone.trim() !== '' &&
      (deliveryOption !== 'Envio a domicilio' || deliveryAddress.trim() !== '');

    setFormIsValid(isValid);
  }, [firstName, lastName, phone, deliveryOption, deliveryAddress]);

  // Método handleSubmit para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault(); // Evita que el formulario se envíe automáticamente

    // Verifica que los campos obligatorios no estén vacíos (aunque este chequeo ya se hace en useEffect)
    if (!formIsValid) {
      alert('Por favor, complete todos los campos obligatorios.');
      return;
    }

    const orderDetails = {
      firstName,
      lastName,
      phone,
      paymentMethod,
      deliveryOption,
      selectedBranch: deliveryOption === 'Envio a domicilio' ? null : selectedBranch,
      deliveryAddress: deliveryOption === 'Envio a domicilio' ? deliveryAddress : null,
      zona
    };

    onSubmit(orderDetails); // Llama a la función onSubmit con los detalles de la orden
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div fluid className='back-checkout'>
          <h2>Finalizar orden</h2>
          <input
            placeholder='Ingrese nombre'
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <br></br>
          <br></br>

          <input
            placeholder='Ingrese apellido'
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <br></br>
          <br></br>
          <input
            placeholder='Ingrese nro. de telefono'
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />

          <div>
            <br></br>
            <b>Elija forma de retiro/ envio:</b>
            <br></br>
            <label className='lb-hover'>
              <input
                type='radio'
                value={"Retiro en sucursal"}
                checked={deliveryOption === 'Retiro en sucursal'}
                onChange={() => setDeliveryOption('Retiro en sucursal')}
              />
              <Icon sucursal={sucursal} />
              Retiro por Sucursal
              <Icon sucursal={sucursal} />
            </label>
          </div>

          <div>
            <label className='lb-hover'>
              <input
                type='radio'
                value={"Envio a domicilio"}
                checked={deliveryOption === 'Envio a domicilio'}
                onChange={() => setDeliveryOption('Envio a domicilio')}
              />
              <Icon sucursal={Delivery} />
              Envío a domicilio (+ {precioEnvio.envio})
              <Icon sucursal={Delivery} />
            </label>
          </div>

          {deliveryOption === 'Envio a domicilio' && (
            <div>
              <br></br>
              <label>Ingrese su domicilio <Icon sucursal={Home} />: </label>
              <input
                placeholder='Recorda ver zonas de delivery'
                type="text"
                value={deliveryAddress}
                onChange={(e) => setDeliveryAddress(e.target.value)}
                required={deliveryOption === 'Envio a domicilio'}
              />
              <br></br>
              <br></br>
              <label>Selecciona tu zona:</label>
              <br></br>
              <label>
                <input
                  type='radio'
                  value={"Zona sucursal 1"}
                  checked={zona === 'Zona sucursal 1'}
                  onChange={() => setZona('Zona sucursal 1')}
                />
                Zona Sucursal 1
              </label>
              <br></br>
              <label>
                <input
                  type='radio'
                  value={"Zona sucursal 2"}
                  checked={zona === 'Zona sucursal 2'}
                  onChange={() => setZona('Zona sucursal 2')}
                />
                Zona Sucursal 2
              </label>
            </div>
          )}

          {deliveryOption === 'Retiro en sucursal' && data.length > 0 && (
            <div>
              <br></br>
              <label>Sucursal: </label>
              {data.map((branch)=>(
                <label key={branch.suc} className='lb-hover'>
                  <input
                    className='inp'
                    type="radio"
                    value={branch.suc} // Usamos el nombre de la sucursal
                    checked={selectedBranch === branch.suc}
                    onChange={() => setSelectedBranch(branch.suc)} // Guardamos el id de la sucursal seleccionada
                  />
                  {branch.name}
                </label>
              ))}
              
              
            </div>
          )}
          <div>
            <br></br>
            <b>Metodo de pago:</b>
            <br></br>
            <label className='lb-hover'>
              <input
                type='radio'
                value={"Efectivo/trasnferencia"}
                checked={paymentMethod === 'Efectivo/transferencia'}
                onChange={() => setPaymentMethod('Efectivo/transferencia')}
              />
              <Icon sucursal={Cash} /> Efectivo/transferencia <Icon sucursal={Cash} />
            </label>
          </div>
          <div>
            {deliveryOption === 'Retiro en sucursal' && (
              <label className='lb-hover'>
                <input
                  type='radio'
                  value={"Tarjeta de credito 1 pago"}
                  checked={paymentMethod === 'Tarjeta de credito 1 pago'}
                  onChange={() => setPaymentMethod('Tarjeta de credito 1 pago')}
                />
                <Icon sucursal={Tc} /> Tarjeta de credito 1 pago <Icon sucursal={Tc} />
              </label>
            )}
          </div>
          <br></br>
          <Message
            firstName={firstName}
            lastName={lastName}
            paymentMethod={paymentMethod}
            deliveryOption={deliveryOption}
            selectedBranch={selectedBranch}
            deliveryAddress={deliveryAddress}
            branches={selectedBranch}
            phone={phone}
            zona={zona}
            formIsValid={formIsValid}
          />
        </div>
      </form>
    </div>
  );
};

export default Checkout;
