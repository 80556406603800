import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';

const EditarHorarios = () => {
  const [localId, setLocalId] = useState(1); // Valor inicial por defecto
  const [horarios, setHorarios] = useState([]);
  const [error, setError] = useState(null); // Estado para manejar errores
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  useEffect(() => {
    if (localId) {
      fetchHorarios();
    }
  }, [localId]);

  const fetchHorarios = async () => {
    try {
      const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/horarios/${localId}`);
      setHorarios(response.data);
    } catch (error) {
      setError('Error al obtener horarios.');
      console.error('Error al obtener horarios:', error);
    }
  };

  // Función para manejar los cambios en los campos de hora_apertura, hora_cierre o cerrado
  const handleChange = (index, field, value) => {
    const nuevosHorarios = [...horarios];
    nuevosHorarios[index][field] = value;
    setHorarios(nuevosHorarios);
  };

  // Función para guardar un día específico
  const handleSaveDay = async (dia) => {
    try {
      await axios.put(`https://pedir-pedir.p89nps.easypanel.host/horarios/${localId}/${dia.dia_semana}`, {
        hora_apertura: dia.hora_apertura,
        hora_cierre: dia.hora_cierre,
        cerrado: dia.cerrado,
      });
      alert(`Horario del día ${dia.dia_semana} actualizado correctamente.`);
    } catch (error) {
      console.error('Error al actualizar horario:', error);
      alert(`Error al actualizar el horario del día ${dia.dia_semana}.`);
    }
  };

  return (
    <Container>
      <h2 className="my-4">Editar Horarios</h2>

      {/* Dropdown para seleccionar la sucursal */}
      <Row className="mb-4">
        <Col>
          <Form.Label>Selecciona una sucursal: </Form.Label>
          <Form.Control as="select" value={localId} onChange={(e) => setLocalId(e.target.value)}>
            <option value={1}>Sucursal 1</option>
            <option value={2}>Sucursal 2</option>
            {/* Puedes agregar más opciones aquí dinámicamente */}
          </Form.Control>
        </Col>
      </Row>

      {/* Mostrar horarios solo si hay datos */}
      {horarios.length > 0 ? (
        horarios.map((dia, index) => (
          <Card key={index} className="mb-4">
            <Card.Body>
              <Card.Title><strong>{diasSemana[dia.dia_semana]}</strong></Card.Title>
              <Row className="mb-3">
                <Col xs={12} md={6}>
                  <Form.Group controlId={`hora_apertura_${index}`}>
                    <Form.Label>Hora Apertura</Form.Label>
                    <Form.Control
                      type="time"
                      value={dia.hora_apertura}
                      onChange={(e) => handleChange(index, 'hora_apertura', e.target.value)}
                      disabled={dia.cerrado === 1} // Desactiva la hora si está cerrado
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId={`hora_cierre_${index}`}>
                    <Form.Label>Hora Cierre</Form.Label>
                    <Form.Control
                      type="time"
                      value={dia.hora_cierre}
                      onChange={(e) => handleChange(index, 'hora_cierre', e.target.value)}
                      disabled={dia.cerrado === 1} // Desactiva la hora si está cerrado
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId={`cerrado_${index}`} className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Cerrado"
                  checked={dia.cerrado === 1}
                  onChange={() => handleChange(index, 'cerrado', dia.cerrado === 1 ? 0 : 1)}
                />
              </Form.Group>

              {/* Botón para guardar cambios de cada día */}
              <Button variant="primary" onClick={() => handleSaveDay(dia)}>Aplicar cambios</Button>
            </Card.Body>
          </Card>
        ))
      ) : (
        <p>No hay horarios disponibles para esta sucursal.</p>
      )}

      {/* Mostrar mensaje de error si ocurre algún problema */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </Container>
  );
};

export default EditarHorarios;
