import React from 'react'
import ItemsListContainer from '../ItemListContainer/ItemsListContainer';
import "../Welcome/Welcome.css";
import { Container } from 'react-bootstrap';
import Hora from './Hora';
import PromotionCarousel from '../Promotion/PromotionCarousel';
import HorarioSucursal from './HorarioSucursal';
const Welcome = () => {
  return (
    <>
    <Container fluid>
        <PromotionCarousel/>
    <div  className='welcome-container' >
    <h2>Bienvenido a Pedir nunca fue tan facil BURBAR!</h2>
    <p>Para poder realizar el pedido de manera agil, tenes que seguir los siguientes pasos:
        <ul>
            <li><b>- Primer paso:</b> selecciona el combo que queres. </li>
            <li><b>- Segundo paso:</b> selecciona tamaño del combo, acompañamientos, salsas y bebida. </li>
            <li><b>- Tercer paso:</b> Agrega el combo y dale al boton de "Volver" para agregar otros combos. </li>
            <li><b>- Cuarto paso:</b> Una vez que tengas todo listo, clickea en el boton de la hamburguesa que se encuentra del lado derecho inferior de la pantalla </li>
            <li><b>- Quinto paso:</b> Chequea que este todo bien tu pedido, y toca el boton "Realizar pedido" </li>
            <li><b>- Sexto paso:</b> Carga tus datos y forma de retiro/envio y metodos de pago (Recorda chequear zonas de entrega). 
            <li><b>- Septimo paso:</b> Dale al boton "Compartir pedido por whatsapp y envianos tu pedido!!". </li>    
            </li>
        </ul>

    </p>
    </div>
    <div className='welcome-container1'>

        <HorarioSucursal/>
    </div>
 {/*    <h2 className='titulo-combos'>Nuestros combos</h2> */}
    </Container>
<ItemsListContainer/>
</>
)
}

export default Welcome