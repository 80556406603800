import { useState, useEffect } from 'react';
import React from 'react';
import { Table, Row, Col, Button, Spinner, Toast, Accordion } from 'react-bootstrap';
import axios from 'axios';

const DeliverySelection = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showAssign, setShowAssign] = useState(false);
  const [showAssignOrder, setShowAssignOrder] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(''); // Para almacenar la sucursal seleccionada
  const [selectedDelivery, setSelectedDelivery] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState({});
  const [nombre, setNombre] = useState('');
  const [sucursal, setSucursal] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totals, setTotals] = useState({ totalRendido: 0, totalRestante: 0 });
  const [branches, setBranches] = useState([]);
  // Fetch deliveries and orders from the server
  const fetchDeliveries = async () => {
    try {
      const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/delivery');
      setDeliveries(response.data);
    } catch (error) {
      console.error('Error fetching deliveries:', error);
    }
  };

  useEffect(() => {
    fetchDeliveries(); // Llamar a la función al montar el componente
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/orders/get');
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone'); // Ajusta esta URL según el endpoint de sucursales
      setBranches(response.data);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };


  //UseEffect inicial
  useEffect(() => {
  // Recuperar estado de los montos y las órdenes del LocalStorage
  const savedStatus = localStorage.getItem('deliveryStatus');
  const savedTotals = localStorage.getItem('totals');

  if (savedStatus) {
      setDeliveryStatus(JSON.parse(savedStatus)); // Actualiza el estado con lo guardado
  }

  if (savedTotals) {
      setTotals(JSON.parse(savedTotals)); // Actualiza los totales
  }

  // Llamar a las funciones de API
  fetchDeliveries();
  fetchOrders();
  fetchBranches(); // Llamada para obtener las sucursales

}, []); // Solo se ejecuta al montar el componente

 // Filtrar las órdenes según la sucursal seleccionada
 const filteredOrder = orders.filter(order => order.selectedBranch === selectedBranch);
 // Filtrar los deliveries según la sucursal seleccionada
 const filteredDeliveries = deliveries.filter(delivery => String(delivery.sucursal) === String(selectedBranch));



//prueba de localstorage
// Maneja el cambio de estado y calcula los totales
const handleStatusChange = (orderId, newStatus) => {
  // Actualiza el estado de deliveryStatus
  setDeliveryStatus((prevState) => {
      const updatedStatus = {
          ...prevState,
          [orderId]: newStatus,
      };

      // Guardar en LocalStorage el estado actualizado
      localStorage.setItem('deliveryStatus', JSON.stringify(updatedStatus));

      // Llama a calculateTotals con el estado actualizado
      calculateTotals(updatedStatus);
      return updatedStatus; // Asegúrate de devolver el nuevo estado
  });
};

// Calcula los totales y los guarda en LocalStorage
const calculateTotals = (updatedDeliveryStatus) => {
  let totalRendido = 0;
  let totalRestante = 0;

  filteredOrders.forEach((order) => {
      const status = updatedDeliveryStatus[order.uid]; // Obtén el estado actualizado
      if (status === 'Finalizado') {
          totalRendido += order.precioFinal; // Sumar al total rendido
      } else {
          totalRestante += order.precioFinal; // Sumar al total restante
      }
  });

  // Actualiza el estado de los totales
  const newTotals = { totalRendido, totalRestante };
  setTotals(newTotals);

  // Guardar los totales en LocalStorage
  localStorage.setItem('totals', JSON.stringify(newTotals));
};




  // Función para agregar un nuevo delivery
 
  const agregarDelivery = async () => {
    setError(null);  // Limpiar error previo
    if (!nombre || !sucursal) {
      setToastMessage('Debes ingresar un nombre y seleccionar una sucursal.');
      setShowToast(true);
      return;
    }

    setLoading(true);


    try {
        const response = await axios.post('https://pedir-pedir.p89nps.easypanel.host/delivery', {
            name: nombre,
            branchId: sucursal,
        });
        console.log("Delivery agregado correctamente:", response.data);
        setNombre("");
        setSucursal("");
        setToastMessage("Delivery agregadi correctamente.");
        setShowToast(true);  // Mostrar el toast de éxito
        fetchDeliveries(); // Actualizar la lista después de agregar

    } catch (error) {
        console.error("Error al agregar delivery:", error);
        setError("Error al agregar delivery. Intente nuevamente.");
    } finally {
        setLoading(false);  // Ocultar el spinner
    }
};

const eliminarDelivery = async () => {
  console.log(selectedDelivery); 
  setLoading(true);
  setError(null);  // Limpiar error previo

  try {
      const response = await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/delivery/${selectedDelivery}`);
      console.log("Delivery eliminado correctamente:", response.data.name); // Acceder al campo name del objeto JSON
      setToastMessage(`Delivery ${response.data.name} eliminado correctamente.`);
      setShowToast(true);  // Mostrar el toast de éxito
      fetchDeliveries(); // Actualizar la lista después de agregar

  } catch (error) {
      console.error("Error al eliminar Delivery:", error);
      setError("Error al eliminar Delivery. Intente nuevamente.");
  } finally {
      setLoading(false);  // Ocultar el spinner
  }
};

const handleAssignOrder = async () => {
  if (!selectedOrder || !selectedDelivery) {
      setToastMessage("Debes seleccionar una orden y un delivery.");
      setShowToast(true);
      return;
  }

    try {
        await axios.put(`https://pedir-pedir.p89nps.easypanel.host/orders/${selectedOrder}/assign`, {
          deliveryId: selectedDelivery,
        });

        
        console.log(selectedDelivery)
        console.log(selectedOrder)

        // Actualizar la lista de órdenes
        fetchOrders(); // Para refrescar la lista de órdenes disponibles
        setToastMessage("Orden asignada correctamente.");
        setShowToast(true);
    } catch (error) {
        console.error("Error al asignar la orden:", error);
        console.log(selectedDelivery)
        console.log(selectedOrder)
        setToastMessage("Error al asignar la orden.");
        setShowToast(true);
    }
  };


const handleRemoveOrder = async () => {
  if (!selectedOrder || !selectedDelivery) {
      setToastMessage("Debes seleccionar una orden y un delivery.");
      setShowToast(true);
      return;
  }

  try {
      await axios.post(`https://pedir-pedir.p89nps.easypanel.host/orders/${selectedOrder}/remove`, {
          deliveryId: null,
      });

      // Actualizar la lista de órdenes
      
      fetchOrders(); // Para refrescar la lista de órdenes disponibles
      setToastMessage("Orden retirada correctamente.");
      setShowToast(true);
  } catch (error) {
      console.error("Error al retirar la orden:", error);
      setToastMessage("Error al retirar la orden.");
      setShowToast(true);
  }
};

const showOrder = () => {
  const filteredOrders = orders.filter(order => {
    return order.deliveryId === Number(selectedDelivery); // Convertir selectedDelivery a número
  });

  setFilteredOrders(filteredOrders); // Actualiza el estado de las órdenes filtradas
  calculateTotals(deliveryStatus); // Calcular totales después de actualizar filteredOrders

  setShowAssignOrder(true);
};


useEffect(() => {
  calculateTotals(deliveryStatus);
}, [filteredOrders, deliveryStatus]);



 // Función para desasignar una orden individual
 const handleUnassignOrder = async (selectedOrder) => {
  setLoading(true);
  try {
    await axios.put(`https://pedir-pedir.p89nps.easypanel.host/orders/${selectedOrder}/assign`, {
      deliveryId: null,
    }); 
    console.log('Orden seleccionada para eliminar:', selectedOrder);

    // Actualiza el estado local de las órdenes eliminando la orden sin esperar a fetchOrders()
    const updatedOrders = orders.filter(order => order.uid !== selectedOrder);
    console.log('Órdenes actualizadas:', updatedOrders);
    setOrders(updatedOrders);
    showOrder();
    fetchOrders();
    // Muestra notificación de éxito
    setToastMessage("Orden eliminada del delivery.");
    setShowToast(true);
  } catch (error) {
    console.error('Error al desasignar la orden:', error);
    setToastMessage("Error al desasignar la orden.");
    setShowToast(true);
  } finally {
    setLoading(false);  // Ocultar el spinner al finalizar
   
  }
  

};


useEffect(() => {
  showOrder();
  fetchOrders(); // Actualizar órdenes cuando cambien los deliveries o las asignaciones
  fetchDeliveries(); // Actualizar la lista de deliveries
}, [selectedDelivery, showAssignOrder, orders]); // 



  return (
    <div>
      <Accordion flush>
        {/* Sección para agregar delivery */}
        <Accordion.Item eventKey="0">
          <Accordion.Header><h4>Agregar delivery:</h4></Accordion.Header>
          <Accordion.Body>
            <input
              placeholder='Ingrese nombre de delivery'
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
             <br /><br />


  {/* Desplegable para seleccionar sucursal */}
  <label>Seleccione una sucursal:</label>
            <select
              value={sucursal}
              onChange={(e) => setSucursal(e.target.value)}
            >
              <option value="">Seleccionar sucursal...</option>
              {branches.map(branch => (
                <option key={branch.suc} value={branch.suc}>
                  {branch.name}
                </option>
              ))}
            </select>
            <br /><br />

            <br /><br />
            <Button variant="success" onClick={agregarDelivery} disabled={loading}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  {' '}Cargando...
                </>
              ) : (
                'Agregar delivery'
              )}
            </Button>
          </Accordion.Body>
        </Accordion.Item>

        {/* Sección para eliminar delivery */}
        <Accordion.Item eventKey="1">
          <Accordion.Header><h4>Eliminar delivery:</h4></Accordion.Header>
          <Accordion.Body>
            <select
              value={selectedDelivery}
              onChange={(e) => setSelectedDelivery(e.target.value)}
            >
              <option value="">Seleccionar delivery...</option>
              {deliveries.map(delivery => (
                <option key={delivery.id} value={delivery.id}>
                  {delivery.name}
                </option>
              ))}
            </select>
            <br /><br />
            <Button variant="danger" onClick={eliminarDelivery} disabled={loading || !selectedDelivery}>
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  {' '}Cargando...
                </>
              ) : (
                'Eliminar delivery'
              )}
            </Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><h4>Ver delivery:</h4></Accordion.Header>
          <Accordion.Body>


{/* Sección para mostrar órdenes */}
      
{/* Filtro de sucursal */}
<label>Seleccione sucursal:</label>
      <select value={selectedBranch} onChange={(e) => setSelectedBranch(e.target.value)}>
        <option value="">Seleccionar sucursal...</option>
        {branches.map((branch) => (
          <option key={branch.id} value={branch.suc}>
            {branch.name}
          </option>
        ))}
      </select>
      <br /><br />

       {/* Filtro de Delivery */}
       <label>Seleccione Delivery:</label>
      <select value={selectedDelivery} onChange={(e) => setSelectedDelivery(e.target.value)}>
        <option value="">Seleccionar delivery...</option>
        {filteredDeliveries.map((delivery) => (
          <option key={delivery.id} value={delivery.id}>
            {delivery.name}
          </option>
        ))}
      </select>
      <br /><br />
      <Accordion flush>
        {/* Sección para agregar delivery */}
        <Accordion.Item eventKey="0">
          <Accordion.Header><h4>Agregar delivery a: {filteredDeliveries.find(delivery => delivery.id === parseInt(selectedDelivery))?.name || 'No seleccionado'}</h4></Accordion.Header>
          <Accordion.Body>

    <>
        <label>Órdenes listas para enviar:</label>
        <select onChange={(e) => setSelectedOrder(e.target.value)} value={selectedOrder}>
    <option value="">Seleccionar orden...</option>
    {filteredOrder.filter(order => order.estado === 'en delivery').map(order => (
        <option 
            key={order.uid} 
            value={order.uid}
            disabled={order.deliveryId !== null} // Deshabilitar si la orden ya tiene delivery asignado
        >
            Orden #{order.uid} {order.deliveryId !== null && "(Asignado)"}
        </option>
    ))}
</select>

        <Button onClick={handleAssignOrder}>Asignar orden a delivery</Button>
        <br /><br />
    </>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header><h4>Ver ordenes asignadas de: {filteredDeliveries.find(delivery => delivery.id === parseInt(selectedDelivery))?.name || 'No seleccionado'}</h4></Accordion.Header>
          <Accordion.Body>
          <>
        <Table striped bordered hover>
    <thead>
        <tr>
            <th>Nro. de orden</th>
            <th>Estado</th>
            <th>Domicilio</th>
            <th>Monto a rendir</th>
            <th>Rendido</th>
            <th>desasignar</th>
        </tr>
    </thead>
    <tbody>
{filteredOrder
  .filter(order => order.deliveryId === Number(selectedDelivery)) // Filtro basado en deliveryId
  .map(order => (
            <tr key={order.uid}>
                <td>{order.uid}</td>
                <td>{deliveryStatus[order.uid] || 'En viaje'}</td>
                <td>{order.deliveryAddress}</td>
                <td>{order.precioFinal}</td>
                <td>
                    <input
                        type='checkbox'
                        checked={deliveryStatus[order.uid] === 'Finalizado'}
                        onChange={(e) => handleStatusChange(order.uid, e.target.checked ? 'Finalizado' : 'En viaje')}
                    />
                </td>
                <td>
                    <Button variant="danger" onClick={() =>  handleUnassignOrder(order.uid) }>
                    {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  {' '}Cargando...
                </>
              ) : (
                <i class="bi bi-trash"></i>
              )}
                    </Button>
                </td>
            </tr>
        ))}
    </tbody>
</Table>

        <Row>
            <Col>
                <h3>Resta rendir: ${totals.totalRestante}</h3>
            </Col>
        </Row>
        <Row>
            <Col>
                <h3>Total rendido: ${totals.totalRendido}</h3>
            </Col>
        </Row>
    </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>    
      
  </Accordion.Body>
 </Accordion.Item>
</Accordion>

      {/* Mostrar Toast de éxito */}
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Notificación</strong>
          <small>Justo ahora</small>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      


    </div>
  );
};

export default DeliverySelection;
