import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Table, Container, Row, Col } from 'react-bootstrap';
import "../Login/CollectionEditor.css";

import Logo from "../Logo/Logo"

const OrderDetails = ({ order, combos, moduloBebida }) => {
  const printRef = useRef();

  // Calcular la cantidad total de carnes
  /* const totalCarnes = combos.reduce((total, combo) => {
    const cantidad = combo.selectedSize === "doble" ? 2 : 1;
    if (combo.carnesExtra === 0){
      return total + (cantidad * combo.quantity);  
    } else if (combo.carnesExtra>0) {
      return total + ((cantidad * combo.quantity)+combo.carnesExtra);
    }
    
  }, 0); */

  const totalCarnes = combos.reduce((total, combo) => {
    const cantidad = combo.selectedSize === "doble" ? 2 : 1;
    
    let carnesExtras = 0;
    if (combo.carnesExtra && typeof combo.carnesExtra === 'number') {
      carnesExtras = combo.carnesExtra * combo.quantity;
    }
  
    return total + (cantidad * combo.quantity) + carnesExtras;
  }, 0);
  

  const totalPanes = combos.reduce((total, combo) => {
    return total + combo.quantity;
  }, 0);

  // Calcular las salsas unificadas
  const acompañamiento = combos.reduce((acc, combo) => {
    const acompa = combo.acomp;
    if (acc[acompa]) {
      acc[acompa] += combo.quantity;
    } else {
      acc[acompa] = combo.quantity;
    }
    return acc;
  }, {});



// Calcular las bebidas unificadas solo si moduloBebida está habilitado
const bebidas = moduloBebida ? combos.reduce((acc, combo) => {
  // Verifica si combo.selectedDrink existe antes de acceder a sus propiedades
  if (combo.selectedDrink && combo.selectedDrink.name) {
    const bebida = combo.selectedDrink.name; // Extrae solo el nombre de la bebida

    // Incrementa la cantidad si la bebida ya existe en el acumulador
    if (acc[bebida]) {
      acc[bebida] += combo.quantity;
    } else {
      acc[bebida] = combo.quantity;
    }
  }
  return acc;
}, {}) : {};  // Retorna un objeto vacío si moduloBebida es false


  // Calcular las bebidas unificadas
/*   const bebidas = combos.reduce((acc, combo) => {
    const bebida = combo.selectedDrink.name;  // Extrae solo el nombre de la bebida
    if (acc[bebida]) {
      acc[bebida] += combo.quantity;
    } else {
      acc[bebida] = combo.quantity;
    }
    return acc;
  }, {});
 */    

  // Calcular las salsas unificadas
  const salsas = combos.reduce((acc, combo) => {
    const salsa = combo.selectedDip;
    if (acc[salsa]) {
      acc[salsa] += combo.quantity;
    } else {
      acc[salsa] = combo.quantity;
    }
    return acc;
  }, {});


  /* handling print */
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });


  const getComboName = (combo) => {
    let comboName = combo.name;
  
    // Lógica para determinar el nombre del combo según la cantidad de carnes extras
    if (combo.selectedSize === 'simple') {
      switch (combo.carnesExtra) {
        case 1:
          comboName = 'Doble';
          break;
        case 2:
          comboName = 'Triple';
          break;
        case 3:
          comboName = 'Cuádruple';
          break;
        
        default:
          comboName = "Simple";
          break;
      }
    } else if (combo.selectedSize === 'doble') {
      switch (combo.carnesExtra) {
        case 1:
          comboName = 'Triple';
          break;
        case 2:
          comboName = 'Cuádruple';
          break;
        case 3:
          comboName = 'Quíntuple';
          break;
        
        default:
          comboName = "Doble";
          break;
      }
    }
  
    return comboName;
  };
  
  console.log(acompañamiento);
  console.log(salsas);
  console.log(bebidas);
  


  return (
    <>
      <Container  ref={printRef}>
      <Row className="print-centered">
<Col> <Logo /></Col></Row>
        <h3>Información de la orden seleccionada:</h3>
      
        <Row>
          <Col className="print-centered"><h5><strong>Orden Nro:#{order.uid}</strong></h5></Col>
          <Col className="print-centered"><h5><strong>Hora de pedido:{order.horaGeneracion}</strong></h5> </Col> {/* Mostrar la cantidad total de panes */}
        </Row>
      
        <Row className="print-centered">

          <Col ><h5><strong>Cliente:{order.firstName} {order.lastName}</strong></h5>
          <h5><strong>Nro. de teléfono: {order.phone}</strong></h5>
          </Col>
        </Row>

        <Row className="print-centered">
        <Col><h5><strong>Total de Carnes:{totalCarnes}</strong></h5> </Col> {/* Mostrar la cantidad total de carnes */}
        <Col><h5><strong>Total de Panes:{totalPanes}</strong></h5> </Col> {/* Mostrar la cantidad total de panes */}
        
        </Row>
        <Table  striped bordered hover>
          <thead>
            <tr>
              <th>Prod</th>
              <th>Cant</th>
              <th>Tam.</th>
              <th>Anot.</th>
            </tr>
          </thead>
          <tbody>
            {combos.map((combo, index) => (
              <tr key={`${combo.id}-${index}`}>
                <td><strong>{combo.name}</strong></td>
                <td><strong>{combo.quantity}</strong></td>
                <td><strong>{getComboName(combo)}</strong></td>
                <td><strong>{combo.anotaciones}</strong></td>
              </tr>
            ))}
          
          
       
          {Object.keys(acompañamiento).map((acompa, index) => (
            <tr key={index}>
              <td><strong>{acompa}</strong></td>
              <td><strong>{acompañamiento[acompa]}</strong></td>
              </tr>
          ))}
        
          {Object.keys(salsas).map((salsa, index) => (
            <tr key={index}>
              <td><strong>{salsa}</strong></td> 
              <td><strong>{salsas[salsa]}</strong></td>
            </tr>
          ))}
        

        
        {moduloBebida && (
  Object.keys(bebidas).map((bebida, index) => (
    <tr key={index}>
      <td><strong>{bebida}</strong></td> 
      <td><strong>{bebidas[bebida]}</strong></td>
    </tr>
  ))
)}
        
        


          </tbody>
          
        </Table>
        
        <Row className="print-centered">
        <Col>
          {order.deliveryOption === "Retiro en sucursal" && (
            <h4><strong>Retiro por sucursal: {order.selectedBranch}</strong></h4>
          )}
          {order.deliveryOption === "Envio a domicilio" && (
            <h5><strong>Domicilio de entrega:{order.deliveryAddress}</strong></h5>
          )}
          <h6>Método de pago: {order.paymentMethod}</h6>
          <h4><strong>Se cobra: ${order.precioFinal}</strong></h4>
          </Col>
        </Row>
        <Row>
          <Col><h6>Estado Actual: {order.estado}</h6></Col>
        </Row>
        
        
      </Container>
      <Button variant="success" onClick={handlePrint}>Imprimir Comanda</Button>
    </>
  );
}

export default OrderDetails;