import React, { useState, useEffect} from 'react';
import { useCartContext } from '../Context/CartContext';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const Message = ({ firstName, lastName, paymentMethod, deliveryOption, selectedBranch, deliveryAddress, phone, zona, formIsValid }) => {
    const { cart, totalPrice } = useCartContext();
    const [phoneData, setPhoneData] = useState([]);
    const [precioEnvio, setPrecioEnvio] =useState ({});
    const [horariosSucursal, setHorariosSucursal] = useState(null);
    const [estaDentroDelHorario, setEstaDentroDelHorario] = useState(true);

    // Fetch horarios
// Función para obtener los teléfonos
const fetchPhoneData = async () => {
    try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
        setPhoneData(response.data);

    } catch (error) {
        console.error('Error al obtener los teléfonos:', error);
    }
};

// Ejecutar la solicitud al montar el componente
useEffect(() => {
    fetchPhoneData();
}, []);

const obtenerEnvio = async () => {
    try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/precio-envio');
        if (response.data.length > 0) {
            setPrecioEnvio(response.data[0]); // Asumiendo que el primer objeto contiene el precio extra
            console.log("Precio envio configurado:", response.data[0].envio);

        } else {
            setPrecioEnvio({ envio: "No disponible" }); // Valor por defecto si no se encuentra
        }
        console.log("Datos de precio envio obtenidos correctamente:", response.data[0].envio);
    } catch (error) {
        console.error("Error al obtener precio evio:", error);

    }
};


useEffect(() => {
    obtenerEnvio();
},[]);


 // Fetch horarios
 const fetchHorarios = async () => {
    const sucursalId = deliveryOption === 'Envio a domicilio'
    ? zona === 'Zona sucursal 1' ? 1 : 2 // Determina la sucursal según la zona
    : selectedBranch; // Usa la sucursal seleccionada para retiro en sucursal
    try {
        const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/horarios/${sucursalId}`);
        setHorariosSucursal(response.data);
    } catch (error) {
        console.error('Error al obtener los horarios:', error);
    }
};

useEffect(() => {
    fetchHorarios();
}, [deliveryOption, zona, selectedBranch]);

// Verificar el horario actual
useEffect(() => {
    if (horariosSucursal) {
        const now = new Date();
        const currentDay = now.getDay(); // 0-6 (domingo a sábado)
        const currentTime = now.getHours() * 60 + now.getMinutes(); // Convertir a minutos del día

        const horarioHoy = horariosSucursal.find(h => h.dia_semana === currentDay);

        if (horarioHoy && !horarioHoy.cerrado) {
            const apertura = parseInt(horarioHoy.hora_apertura.split(':')[0]) * 60 + parseInt(horarioHoy.hora_apertura.split(':')[1]);
            const cierre = parseInt(horarioHoy.hora_cierre.split(':')[0]) * 60 + parseInt(horarioHoy.hora_cierre.split(':')[1]);

            setEstaDentroDelHorario(currentTime >= apertura && currentTime <= cierre);
        } else {
            setEstaDentroDelHorario(false); // Si está cerrado hoy
        }
    }
}, [horariosSucursal]);





    //aca irian los numeros de las sucursales.
    const handleWhatsAppShare = async () => {
        console.log(estaDentroDelHorario)
        if (!estaDentroDelHorario) {
            alert("No se pueden generar órdenes fuera del horario de atención.");
            return;
        }

        let precioFinal = totalPrice(); // Calcular el precio final al momento de enviar el mensaje

        const phoneForSucursal1 = phoneData.find(phone => phone.suc === 1); 
        console.log(phoneForSucursal1);
        const phoneForSucursal2 = phoneData.find(phone => phone.suc === 2); 
        console.log(phoneForSucursal1);

        if (deliveryOption === 'Envio a domicilio') {
          precioFinal += precioEnvio.envio;
          console.log("PRECIO TOTAL" + precioFinal)
          console.log("ZONA SELECCIONADA:"+zona)
          console.log("PRECIO ENVIO" + precioEnvio.envio)
        }

      let phoneNumber = null;
      if (deliveryOption === "Retiro en sucursal"){ 
       if( selectedBranch=== 1 ){
        
        if (phoneForSucursal1){
            console.log("sucursal:"+phoneData.suc);
            phoneNumber=phoneForSucursal1.telefono;
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        }
        } 
      else if (selectedBranch===2){

        if (phoneForSucursal2){
            phoneNumber=phoneForSucursal2.telefono;
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        }
       }
       
    }
    if (deliveryOption === "Envio a domicilio"){ 
        if( zona==="Zona sucursal 1"){
            phoneNumber=phoneForSucursal1.telefono;
            selectedBranch="1";
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        } else if (zona==="Zona sucursal 2"){
            phoneNumber= phoneForSucursal2.telefono;
            selectedBranch="2";
            console.log(selectedBranch)
            console.log(zona)
            console.log(phoneNumber)
        }
      }



      const message = buildMessage(cart, precioFinal); // Pasar el precio final a la función buildMessage
     // Obtener la hora actual ajustada a la zona horaria local del cliente
const currentTime = new Date();

// Función para obtener fecha en formato 'yyyy-mm-dd'
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Asegura dos dígitos
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Función para obtener hora en formato 'hh:mm:ss'
const formatTime = (date) => {
  const hours = String(date.getHours()).padStart(2, '0'); // Asegura dos dígitos
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

// Crear el objeto `orderDetails` con la fecha y hora corregidas
const orderDetails = {
  firstName,
  lastName,
  phone,
  deliveryOption,
  deliveryAddress,
  selectedBranch,
  paymentMethod,
  estado: "pendiente",
  combos: cart,
  precioFinal,
  fechaGeneracion: formatDate(currentTime), // Fecha en formato 'yyyy-mm-dd'
  horaGeneracion: formatTime(currentTime), // Hora en formato 'hh:mm:ss'
};
console.log('Fecha enviada:', formatDate(currentTime));
console.log('Hora enviada:', formatTime(currentTime));

// Enviar los detalles de la orden al backend
try {
  await axios.post('https://pedir-pedir.p89nps.easypanel.host/orders', orderDetails);
  console.log('Detalles de la orden enviados correctamente');
} catch (error) {
  console.error('Error al enviar los detalles de la orden:', error);
}


        
        if (phoneNumber) {
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            console.error("Número de teléfono no especificado para la zona o sucursal seleccionada");
        }
    };

    const buildMessage = (cart, precioFinal) => {
        let message = `Hola buenas noches! mi nombre es *${firstName} ${lastName}*, te detallo el pedido que quiero realizar:\n`;

        cart.forEach((item, index) => {
            message += `\n*- Combo nro:* ${index + 1}:\n`;
            message += `*- Combo:* ${item.name}\n`;
            message += `*- Cantidad:* ${item.quantity}\n`;
            message += `*- Tamaño:* ${item.selectedSize}\n`;
            message += `*- Acompañamiento:* ${item.acomp}\n`;
            message += `*- Salsa:* ${item.selectedDip}\n`;
           /*  message += `*- Bebida:* ${item.selectedDrink}\n`; */
           message += `*- Bebida:* ${item.selectedDrink.name || item.selectedDrink}\n`;

            message += `*- Medallones extras:* ${item.extras.medallones}\n`;
            message += `*- Anotaciones:* ${item.anotaciones}\n`;
            message += `*-Precio:* $${item.price}\n`;
        });

        if (deliveryOption === 'Envio a domicilio') {
            message += `*- Y necesito que lo envíen a:* ${deliveryAddress}\n`;
        } else {
            message += `*- Y voy a ir a retirarlo a:* ${selectedBranch}\n`;
        }

        message += `\n*- El metodo de pago es:* ${paymentMethod}\n`;
        message += "*Precio total a pagar:* $";
        message += precioFinal;
        message += `\n*Muchas gracias!!*`;

        return message;
    };

    return (
        <div>
            <Button     disabled={!formIsValid || !estaDentroDelHorario} variant="success" onClick={handleWhatsAppShare}>Compartir Pedido por WhatsApp <i className="bi bi-whatsapp bwp"></i></Button>
{!estaDentroDelHorario && (
                <p style={{ color: 'red' }}>La sucursal está cerrada. No se pueden realizar pedidos en este momento.</p>
            )}
        </div>
    );
};

export default Message;
