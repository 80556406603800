import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Registramos los componentes de Chart.js que vamos a usar
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const HardcodedChart = () => {
  // Datos hardcodeados
  console.log('HardcodedChart rendered');  // Esto debería aparecer en la consola

  const data = {
    labels: ['Sucursal 1', 'Sucursal 2', 'Sucursal 3', 'Sucursal 4'], // Nombres de las sucursales
    datasets: [
      {
        label: 'Conversiones',  // Etiqueta de las barras
        data: [12, 19, 3, 5],   // Datos de conversiones por sucursal
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Color de las barras
        borderColor: 'rgba(75, 192, 192, 1)', // Color del borde
        borderWidth: 1,  // Ancho del borde
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Métricas de Conversiones por Sucursal',
      },
    },
  };

  return (
    <div>
      <h2>Gráfico de Conversiones</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default HardcodedChart;
