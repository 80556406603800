import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCartContext } from '../Context/CartContext';
import axios from 'axios';

const DrinksDropdown = () => {
  const [drinks, setDrinks] = useState([]);
  const { selectedDrink, setSelectedDrink } = useCartContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/drinks`);
        setDrinks(response.data);
      } catch (error) {
        console.error('Error al obtener las bebidas:', error);
      }
    };

    fetchData();
  }, []);

  const handleDrinkChange = event => {
    const selectedDrinkName = event.target.value;
    const selectedDrinkObj = drinks.find(drink => drink.name === selectedDrinkName);
    setSelectedDrink(selectedDrinkObj);
  };

  return (
    <div>
      <label className='underline'><b>Seleccione una bebida</b></label>
      <br />
      <select value={selectedDrink ? selectedDrink.name : ""} onChange={handleDrinkChange}>
        <option value="">Seleccione una bebida</option>
        {drinks.map(drink => (
          <option key={drink.id} value={drink.name}>
            {drink.name} - {drink.tamaño} {drink.precio === 0 ? "Gratis" : `+ $${drink.precio}`}
          </option>
        ))}
      </select>
      <br /><br />
      {selectedDrink && <p>Seleccionaste: <b>{selectedDrink.name}</b></p>}
    </div>
  );
};

export default DrinksDropdown;
