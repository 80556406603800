import React from 'react';
import { useCartContext } from '../Context/CartContext';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../ItemCart/Itemcart.css";
import toast, { Toaster } from 'react-hot-toast';
import { Button } from 'react-bootstrap';

const ItemCart = ({ item, moduloBebida }) => {
  const { removeProduct } = useCartContext();
console.log("Modulo de bebida:"+moduloBebida);
console.log("Props recibidos:", item);  // Verifica en la consola

  // Verifica si selectedDrink existe antes de acceder a sus propiedades
// Manejo de bebida solo si el módulo está habilitado
const drinkName = moduloBebida && item.selectedDrink ? item.selectedDrink.name : 'No seleccionado';
const drinkPrice = moduloBebida && item.selectedDrink ? item.selectedDrink.precio : 0;

  // Calcular el subtotal incluyendo la bebida
  const subtotal = item.quantity * (item.price + drinkPrice);

  const notify1 = () => {
    toast.error('Combo eliminado!');
    removeProduct(item.id, item.selectedSize, item.acomp, item.selectedDrink, item.selectedDip);
  };

  return (
    <Container fluid className='itemCart' style={{ border: "1px solid black" }}>
      <Row>
        <Col>
          <img src={item.image} alt={item.name} className='item-modal' />
        </Col>
        <Col>
          <div>
            <p><b>Nombre:</b> {item.name}</p>
            <p><b>Tamaño:</b> {item.selectedSize}</p>
            <p><b>Cantidad:</b> {item.quantity}</p>
            <p><b>Acompañamiento:</b> {item.acomp}</p>
            <p><b>Salsa:</b> {item.selectedDip}</p>
            {/* Mostrar bebida solo si el módulo está habilitado */}
            {moduloBebida && (
              <p><b>Bebida:</b> {drinkName}</p>
            )}
            <p><b>Anotaciones:</b> {item.anotaciones || 'Ninguna'}</p>
            <p><b>Medallones Extra:</b> {item.extras?.medallones || 'No aplica'}</p>
            <p><b>Precio por Combo:</b> ${item.price.toFixed(2)}</p>
            <p><b>Subtotal:</b> ${subtotal.toFixed(2)}</p>
            <Button variant="danger" onClick={notify1}>Eliminar</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ItemCart;
