import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';

const ExportOrdersButton = () => {
  const [sucursal, setSucursal] = useState(1); // Estado para la sucursal seleccionada
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Maneja la exportación de órdenes a Excel
  const handleExportOrders = async () => {
    try {
      const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/orders?sucursal=${sucursal}`, { responseType: 'blob' });
      
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `orders_finalizadas_${new Date().toISOString().slice(0, 10)}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        console.error('No se recibió archivo de la API.');
      }
    } catch (error) {
      console.error('Error al exportar las órdenes:', error);
    }
  };

  // Maneja la finalización del día
  const handleFinalizarDia = async () => {
    setLoading(true);
    setError(null);
    try {
      const fechaActual = new Date().toISOString(); // Fecha y hora actual en formato ISO

      await axios.put('https://pedir-pedir.p89nps.easypanel.host/orders/finalizar-dia', {
        sucursalId: sucursal,
        fechaActual,
      });
      alert('Día finalizado correctamente. Todas las órdenes han sido archivadas.');
    } catch (error) {
      console.error('Error al finalizar el día:', error);
      setError('Error al finalizar el día. Intenta nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Formulario de selección de sucursal */}
      <Form.Group controlId="formSucursal">
        <Form.Label>Seleccionar Sucursal</Form.Label>
        <Form.Control as="select" value={sucursal} onChange={(e) => setSucursal(e.target.value)}>
          <option value={1}>Sucursal 1</option>
          <option value={2}>Sucursal 2</option>
        </Form.Control>
      </Form.Group>

      {/* Botones de exportación y finalizar día */}
      <Button onClick={handleExportOrders} className="m-2">
        Exportar Órdenes Finalizadas a Excel
      </Button>
      <Button onClick={handleFinalizarDia} className="m-2 btn-danger" disabled={loading}>

        {loading ? 'Procesando...' : 'Finalizar Día'}
      </Button>

      {/* Mostrar mensaje de error si ocurre algún problema */}
      {error && <p className="text-danger mt-2">{error}</p>}
    </div>
  );
};

export default ExportOrdersButton;
