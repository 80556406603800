import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dips from '../Dips/Dips';  // Suponiendo que Dips muestra las salsas
import { useCartContext } from '../Context/CartContext';
import "../Checkout/checkout.css";
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const CollectionList1 = () => {
    const [nombre, setNombre] = useState("");
    const [salsas, setSalsas] = useState([]);  // Estado para la lista de salsas
    const { selectedDip, setSelectedDip } = useCartContext();
    const [loading, setLoading] = useState(false);  // Estado para spinner
    const [showToast, setShowToast] = useState(false);  // Estado para la notificación
    const [error, setError] = useState(null);  // Estado para manejar errores

    // Función para obtener las salsas desde el servidor
    const obtenerSalsas = async () => {
        try {
            const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/sauces');
            setSalsas(response.data);  // Guardar las salsas en el estado
        } catch (error) {
            console.error("Error al obtener salsas:", error);
        }
    };

    // Llamar a obtenerSalsas cuando el componente se monta
    useEffect(() => {
        obtenerSalsas();
    }, []);

    const agregarSalsa = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            const response = await axios.post('https://pedir-pedir.p89nps.easypanel.host/sauces', {
                name: nombre,
            });
            setSalsas([...salsas, response.data]);  // Agregar la nueva salsa a la lista
            setNombre("");
            setShowToast(true);  // Mostrar notificación de éxito
            obtenerSalsas();
        } catch (error) {
            console.error("Error al agregar salsa:", error);
            setError("Error al agregar salsa");
        } finally {
            setLoading(false);  // Ocultar spinner
        }
    };

    const eliminarSalsa = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/sauces/${selectedDip}`);
            // Actualizar la lista de salsas eliminando la salsa borrada
            setSalsas(salsas.filter(salsa => salsa.id !== selectedDip));
            setShowToast(true);  // Mostrar notificación de éxito
            obtenerSalsas();
        } catch (error) {
            console.error("Error al eliminar salsa:", error);
            setError("Error al eliminar salsa");
        } finally {
            setLoading(false);  // Ocultar spinner
        }
    };


    
    return (
        <>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Agregar salsa:</h4></Accordion.Header>
                    <Accordion.Body>
                        <input
                            placeholder='Ingrese nombre de Salsa'
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <br></br>
                        <Button className="btn-inicio" variant="success" onClick={agregarSalsa} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Agregar salsa'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Eliminar salsa:</h4></Accordion.Header>
                    <Accordion.Body>
                        <Dips salsas={salsas} /> {/* Pasamos las salsas a Dips para mostrarlas */}
                        <Button className="btn-inicio" variant="danger" onClick={eliminarSalsa} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Eliminando...
                                </>
                            ) : (
                                'Eliminar salsa'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Notificación de éxito */}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small>Justo ahora</small>
                </Toast.Header>
                <Toast.Body>Operación realizada con éxito.</Toast.Body>
            </Toast>

            {/* Mostrar error si existe */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default CollectionList1;
