import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, EffectCoverflow } from 'swiper/modules'; // Importa módulos correctamente

// Importa estilos globales de Swiper
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import './PromotionCarousel.css'; // Tu archivo CSS personalizado
import Item from '../Item/Item';


const PromotionCarousel = () => {
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/api/productos');
        const promoItems = response.data.filter(item => item.promocion === 'si');
        setPromotions(promoItems);
      } catch (error) {
        console.error('Error al obtener productos en promoción:', error);
      }
    };

    fetchPromotions();
  }, []);

  return (
    <div>
      <br></br>
      <div className="promotion-carousel-container">
      <h2>Combos en promo!!</h2>
        <Swiper
          modules={[Autoplay, Navigation, Pagination, EffectCoverflow]}
          effect="coverflow"
          spaceBetween={20}
          slidesPerView={1}
          breakpoints={{
            464: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3   },
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
        >
          {promotions.map(promo => (
            <SwiperSlide key={promo.id}>
              <Item item={promo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PromotionCarousel;
