import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tooltip, OverlayTrigger } from 'react-bootstrap'; // Importamos para el tooltip
import "../Welcome/HorarioSucursal.css";

const HorarioSucursal = () => {
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    // Cargar datos de las sucursales desde el endpoint existente
    const fetchSucursales = async () => {
      try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
        setSucursales(response.data); // Asegúrate de que response.data tenga `suc` y `nombre`
      } catch (error) {
        console.error('Error al obtener los datos de las sucursales:', error);
      }
    };

    fetchSucursales();
  }, []);

  return (
    <div className='contenedor-horario'>
      {sucursales.map((sucursal) => (
        <SucursalComponent key={sucursal.suc} sucursal={sucursal} />
      ))}
    </div>
  );
};

const SucursalComponent = ({ sucursal }) => {
  const [horarios, setHorarios] = useState([]);
  const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const hoy = new Date();
  const diaActual = hoy.getDay(); // Día actual (0: Domingo, ..., 6: Sábado)

  useEffect(() => {
    // Cargar horarios para cada sucursal
    const fetchHorarios = async () => {
      try {
        const horariosResponse = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/horarios/${sucursal.suc}`);
        setHorarios(horariosResponse.data.sort((a, b) => a.dia_semana - b.dia_semana));
      } catch (error) {
        console.error(`Error al obtener los horarios de la sucursal ${sucursal.suc}:`, error);
      }
    };

    fetchHorarios();
  }, [sucursal.suc]);

  // Obtener el horario del día actual
  const horarioHoy = horarios.find((h) => h.dia_semana === diaActual);

  // Tooltip con todos los horarios de la semana
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {horarios.map((h) => (
        <div key={h.dia_semana}>
          <strong>{diasSemana[h.dia_semana]}:</strong>{' '}
          {h.cerrado ? 'Cerrado' : `${h.hora_apertura} - ${h.hora_cierre}`}
        </div>
      ))}
    </Tooltip>
  );

// Función para verificar si la sucursal está abierta hoy
const estaAbierto = (horario) => {
  if (!horario || horario.cerrado) return false;

  const ahora = new Date(); // Hora actual del servidor
  
  // Convertimos las horas de apertura y cierre a objetos Date en el mismo día
  const [horaA, minutoA] = horario.hora_apertura.split(':');
  const [horaC, minutoC] = horario.hora_cierre.split(':');

  const apertura = new Date();
  apertura.setHours(horaA, minutoA, 0, 0);

  const cierre = new Date();
  cierre.setHours(horaC, minutoC, 0, 0);

  // Comparamos si la hora actual está entre la apertura y el cierre
  return ahora >= apertura && ahora <= cierre;
};


  // Determina si está abierto o cerrado hoy
  const estadoSucursal = horarioHoy && estaAbierto(horarioHoy) ? 'abierto' : 'cerrado';

  return (
    <>
    <div className='contenedor-horario'>
    <div className="horario-sucursal">
      <h3>{sucursal.nombre || `Sucursal ${sucursal.suc}`}</h3>

      {/* Mostrar solo el día actual */}
      <div className={`dia-actual ${estadoSucursal}`}>
        <h4>{diasSemana[diaActual]}</h4>
        <div className={`estado-circulo ${estadoSucursal}`}></div>
        <p>
          {horarioHoy ? (
            horarioHoy.cerrado ? 'Cerrado' : `${horarioHoy.hora_apertura} - ${horarioHoy.hora_cierre}`
          ) : (
            'No disponible'
          )}
        </p>

        {/* Ícono de información con tooltip */}
        <OverlayTrigger placement="top" overlay={renderTooltip}>
        <i class="bi bi-info-circle-fill"></i>
        </OverlayTrigger>
      </div>
    </div>
    </div>
  
  </>
  );
};

export default HorarioSucursal;
