import React, { useState, useEffect } from 'react';
import DrinksDropdown from '../Drinks/DrinksDropdown';
import axios from 'axios';
import { useCartContext } from '../Context/CartContext';
import "../Checkout/checkout.css";
import { Button, Spinner, Toast,Tooltip, OverlayTrigger } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const CollectionList = () => {
    const [nombre, setNombre] = useState("");
    const [tamaño, setTamaño] = useState("");
    const [precioBebida, setPrecioBebida] = useState();
    const { selectedDrink } = useCartContext();
    const [loading, setLoading] = useState(false);  // Estado para mostrar spinner
    const [showToast, setShowToast] = useState(false);  // Estado para el toast de éxito
    const [toastMessage, setToastMessage] = useState("");  // Mensaje del toast
    const [error, setError] = useState(null);  // Estado para manejar errores
    const [bebidaSuc, setBebidaSuc] = useState({});
    const [bebidaHabilitada, setBebidaHabilitada] = useState(false);
    const [moduloBebida, setModuloBebida] = useState(false);  // Estado del módulo de bebidas


 // Función para obtener el estado del módulo de bebidas
 const obtenerEstadoModuloBebida = async () => {
    try {
        const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
        if (response.data.length > 0) {
            setModuloBebida(response.data[0].modulo_bebida === 1);
            console.log("Modulo de bebida"+moduloBebida)
        } else {
            setModuloBebida(false);
        }
    } catch (error) {
        console.error("Error al obtener el estado del módulo de bebidas:", error);
    }
};

// Ejecutar la solicitud al montar el componente
useEffect(() => {
    obtenerEstadoModuloBebida();
}, []);


  // Función para actualizar el estado del módulo en el servidor
  const actualizarEstadoModuloBebida = async (nuevoEstado) => {
    try {
        await axios.put('https://pedir-pedir.p89nps.easypanel.host/activateModuleDrink', {
            modulo_bebida: nuevoEstado ? 1 : 0,
        });
        console.log("Estado del módulo de bebidas actualizado a:", nuevoEstado);
    } catch (error) {
        console.error('Error al actualizar el estado del módulo de bebidas:', error);
    }
};




 // Manejar cambio de estado del checkbox
 const handleCheckboxModule = async () => {
    const nuevoEstado = !moduloBebida;  // Invierte el estado actual
    try {
        await actualizarEstadoModuloBebida(nuevoEstado);  // Llama a la función de actualización
        setModuloBebida(nuevoEstado);  // Actualiza el estado local si la llamada fue exitosa
    } catch (error) {
        console.error("Error al cambiar el estado del módulo de bebidas:", error);
    }
};


    const habilitarPrecioBebida = async () =>{            try {
                const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
                if (response.data.length > 0) {
                    // Suponiendo que la respuesta contiene el campo 'bebida'
                    setBebidaHabilitada(response.data[0].bebida === 1); // Convierte a booleano
                  } else {
                    setBebidaHabilitada(false); // Valor por defecto si no hay datos
                  
                }
                console.log("Datos de precio envio obtenidos correctamente:", response.data[0].bebida);
            } catch (error) {
                console.error("Error al obtener precio evio:", error);
        
            }
        };
        
        // Ejecutar la solicitud al montar el componente
        useEffect(() => {
            habilitarPrecioBebida();
        }, []);
    
        const handleCheckboxChange = () => {
            const nuevoEstado = !bebidaHabilitada;  // Invertir el estado actual
            setBebidaHabilitada(nuevoEstado);       // Actualizar estado local
          
            // Realizar la llamada al servidor
            habilitarPrecio(nuevoEstado);
          };



        const habilitarPrecio = async (nuevoEstado) => {
            try {
              await axios.put('https://pedir-pedir.p89nps.easypanel.host/activateDrink', {
                bebida: nuevoEstado ? 1 : 0,  // Enviar 1 si está habilitado, 0 si no
              });
              console.log("Estado de bebida actualizado a:", nuevoEstado);
            } catch (error) {
              console.error('Error al actualizar el estado de la bebida:', error);
            }
          };
          
    
    // Ejecutar la solicitud al montar el componente
    useEffect(() => {
        habilitarPrecioBebida();
    }, []);

    

    const agregarBebida = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            const response = await axios.post('https://pedir-pedir.p89nps.easypanel.host/drinks', {
                name: nombre,
                tamaño: tamaño,
                precio: precioBebida,
            });
            console.log("Bebida agregada correctamente:", response.data);
            setNombre("");
            setTamaño("");
            setPrecioBebida(0);
            setToastMessage("Bebida agregada correctamente.");
            setShowToast(true);  // Mostrar el toast de éxito
        } catch (error) {
            console.error("Error al agregar bebida:", error);
            setError("Error al agregar bebida. Intente nuevamente.");
        } finally {
            setLoading(false);  // Ocultar el spinner
        }
    };

    const eliminarBebida = async () => {
        setLoading(true);
        setError(null);  // Limpiar error previo

        try {
            const response = await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/drinks/${selectedDrink}`);
            console.log("Bebida eliminada correctamente:", response.data.name);
            setToastMessage("Bebida eliminada correctamente.");
            setShowToast(true);  // Mostrar el toast de éxito
        } catch (error) {
            console.error("Error al eliminar bebida:", error);
            setError("Error al eliminar bebida. Intente nuevamente.");
        } finally {
            setLoading(false);  // Ocultar el spinner
        }
    };

 // Tooltip con todos los horarios de la semana
 const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    <p>Esta funcion habilita o desahibilita el modulo de bebidas</p>
    </Tooltip>
  );

    return (
        <>
<br /><br />
<label>Habilitar modulo bebida en bebida?</label>
                        <input
                            type='checkbox'
                            checked={moduloBebida}
                            onChange={(e) => {
                                handleCheckboxModule(e);  // Actualiza el estado local
                              }}
                            
                        />
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
        <i class="bi bi-info-circle-fill"></i>
        </OverlayTrigger>
              <br /><br />

 {/* Mostrar el módulo solo si está habilitado */}
 {moduloBebida && (
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Agregar bebida:</h4></Accordion.Header>
                    <Accordion.Body>
                        <input
                            placeholder='Ingrese nombre de bebida'
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <br /><br />
                        <input
                            placeholder='Ingrese tamaño de bebida'
                            value={tamaño}
                            onChange={(e) => setTamaño(e.target.value)}
                        />
                        <br /><br />
                        <label>Habilitar precio en bebida?</label>
                        <input
                            type='checkbox'
                            checked={bebidaHabilitada}
                            onChange={(e) => {
                                handleCheckboxChange(e);  // Actualiza el estado local
                              }}
                        />
              <br /><br />
                  {bebidaHabilitada &&
                  <>
                  <input
                  placeholder='Precio de la bebida'
                  value={precioBebida}
                  onChange={(e) => setPrecioBebida(e.target.value)}
              />
              <br /><br />
                  
              </>}      
              <br /><br />
                        <Button variant="success" onClick={agregarBebida} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Agregar bebida'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Eliminar bebida:</h4></Accordion.Header>
                    <Accordion.Body>
                        <DrinksDropdown />
                        <Button variant="danger" onClick={eliminarBebida} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Eliminar bebida'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
 )}
            {/* Mostrar Toast de éxito */}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small>Justo ahora</small>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>

            {/* Mostrar error si existe */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default CollectionList;
