import React, { useState, useEffect } from 'react';
import "../Checkout/checkout.css";
import axios from 'axios';
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const ManualOrder = () => {
    const [burguer, setItem] = useState([]);
    const [selectedBurguer, setSelectedBurguer] = useState(null);
    const [newName, setNewName] = useState('');
    const [newPromo, setNewPromo] = useState(false);
    const [promoActual, setPromoActual] = useState("");
    const [newDescription, setNewDescription] = useState('');
    const [newSimple, setNewSimple] = useState('');
    const [newDoble, setNewDoble] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://pedir-pedir.p89nps.easypanel.host/api/productos`);
                setItem(response.data);
            } catch (error) {
                console.error('Error al obtener el producto:', error);
            }
        };

        fetchData();
    }, []);

    const handleUpdate = async (field, value) => {
      if (!selectedBurguer) return;
      setLoading(true);
      setError(null);
  
      // Si el campo es 'promocion', convertimos el valor booleano en "Sí" o "No"
      if (field === 'promocion') {
          value = value ? 'si' : 'no';  // Convierte true a "Sí" y false a "No"
      }
  
      try {
          await axios.put(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.id}/${field}`, { [field]: value });
          console.log(`${field} actualizado correctamente en MySQL`);
          setToastMessage(`${field} actualizado correctamente.`);
          setShowToast(true);
      } catch (error) {
          console.error(`Error al actualizar ${field} en MySQL:`, error);
          setError(`Error al actualizar ${field}. Intente nuevamente.`);
      } finally {
          setLoading(false);
      }
  };
  
    const eliminarCombo = async () => {
        if (!selectedBurguer) return;
        setLoading(true);
        setError(null);
        try {
            await axios.delete(`https://pedir-pedir.p89nps.easypanel.host/item/${selectedBurguer.name}`);
            console.log("Combo eliminado correctamente");
            setToastMessage("Combo eliminado correctamente.");
            setShowToast(true);
            setSelectedBurguer(null);
        } catch (error) {
            console.error("Error al eliminar combo:", error);
            setError("Error al eliminar el combo. Intente nuevamente.");
        } finally {
            setLoading(false);
        }
    };

/*     const handleBurguerSelection = (event) => {
        const selectedComboName = event.target.value;
        const selectedCombo = burguer.find(combo => combo.name === selectedComboName);
        setSelectedBurguer(selectedCombo);
        if (selectedCombo) {
            setNewName(selectedCombo.name);
            setNewDescription(selectedCombo.description);
            setNewSimple(selectedCombo.simple);
            setNewDoble(selectedCombo.doble);
            setNewPromo(selectedCombo.promocion);
        }
    }; */

    const handleBurguerSelection = (event) => {
      const selectedComboName = event.target.value;
      const selectedCombo = burguer.find(combo => combo.name === selectedComboName);
      setSelectedBurguer(selectedCombo);
      if (selectedCombo) {
          setNewName(selectedCombo.name);
          setNewDescription(selectedCombo.description);
          setNewSimple(selectedCombo.simple);
          setNewDoble(selectedCombo.doble);
          setPromoActual(selectedCombo.promocion); // Aquí guardamos el valor de promocion
          setNewPromo(selectedCombo.promocion === 'si'); // Sincroniza el estado de la promoción con el valor 'si' o 'no'
      }
  };
  


/*     const handlePromoChange = (e) => {
        setNewPromo(e.target.checked);
    }; */

    const handlePromoChange = (e) => {
      setNewPromo(e.target.checked);
      // Si necesitas sincronizar con la base de datos inmediatamente, puedes llamar a handleUpdate
      // handleUpdate('promocion', e.target.checked ? 'si' : 'no');
  };
  

    return (
        <div>
            <label className='underline'><b>Seleccione combo</b></label>
            <br />
            <select value={selectedBurguer ? selectedBurguer.name : ''} onChange={handleBurguerSelection}>
                <option value="">Seleccione un combo</option>
                {burguer.map(br => (
                    <option key={br.id} value={br.name}>
                        {br.name}
                    </option>
                ))}
            </select>
            <br /><br />

            {selectedBurguer && (
                <>
                    <p>Seleccionaste: <b>{selectedBurguer.name}</b></p>
                    <div>
                        <Button variant='danger' onClick={eliminarCombo} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Eliminar combo'
                            )}
                        </Button>
                        <br /><br />

                        <Accordion flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h4>Modificar combo</h4></Accordion.Header>
                                <Accordion.Body>
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Nombre</Accordion.Header>
                                            <Accordion.Body>
                                                <input
                                                    placeholder='Nuevo nombre'
                                                    value={newName}
                                                    onChange={(e) => setNewName(e.target.value)}
                                                />
                                                <Button variant='secondary' onClick={() => handleUpdate('name', newName)} disabled={loading}>
                                                    {loading ? 'Cargando...' : 'Modificar nombre'}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Descripción</Accordion.Header>
                                            <Accordion.Body>
                                                <textarea
                                                    placeholder='Nueva descripción'
                                                    value={newDescription}
                                                    onChange={(e) => setNewDescription(e.target.value)}
                                                />
                                                <Button variant='secondary' onClick={() => handleUpdate('description', newDescription)} disabled={loading}>
                                                    {loading ? 'Cargando...' : 'Modificar descripción'}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Precio simple</Accordion.Header>
                                            <Accordion.Body>
                                                <input
                                                    placeholder='Nuevo precio simple'
                                                    value={newSimple}
                                                    onChange={(e) => setNewSimple(e.target.value)}
                                                />
                                                <Button variant='secondary' onClick={() => handleUpdate('simple', newSimple)} disabled={loading}>
                                                    {loading ? 'Cargando...' : 'Modificar precio simple'}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Precio doble</Accordion.Header>
                                            <Accordion.Body>
                                                <input
                                                    placeholder='Nuevo precio doble'
                                                    value={newDoble}
                                                    onChange={(e) => setNewDoble(e.target.value)}
                                                />
                                                <Button variant='secondary' onClick={() => handleUpdate('doble', newDoble)} disabled={loading}>
                                                    {loading ? 'Cargando...' : 'Modificar precio doble'}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Promoción</Accordion.Header>
                                            <Accordion.Body>
                                                <input
                                                    type='checkbox'
                                                    checked={newPromo}
                                                    onChange={handlePromoChange}
                                                />
                                                <span>{newPromo ? 'Sí' : 'No'}</span>
                                                <Button variant='secondary' onClick={() => handleUpdate('promocion', newPromo)} disabled={loading}>
                                                    {loading ? 'Cargando...' : 'Modificar promoción'}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </>
            )}

            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>

            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default ManualOrder;
