import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ItemCount from '../ItemCount/ItemCount';
import { Link } from 'react-router-dom';
import { useCartContext } from '../Context/CartContext';
import DrinksDropdown from '../Drinks/DrinksDropdown';
import Dips from '../Dips/Dips';
import MedallonesDropdown from '../Extra/MedallonesDropdown';
import "../ItemDetail/ItemDetail.css"; // Asegúrate de tener tu archivo CSS personalizado
import axios from 'axios';


const ItemDetail = ({ item }) => {
  const [price, setPrice] = useState("doble");
  const { addProduct, selectedSize, setSelectedSize, selectedDrink, setSelectedDrink, selectedDip, anotaciones, setAnotacion,precioExtra, precioBebida } = useCartContext();
  const [goToCart, setGoToCart] = useState(false);
  const [acomp, setAcomp] = useState("Papas fritas");
  const [formComplete, setFormComplete] = useState(false);
  const [precioExtras, setPrecioExtras] = useState(0); // Estado para almacenar el costo adicional de los medallones extra
  const [medallonesExtras, setMedallonesExtras] = useState(0);
  const [moduloBebida, setModuloBebida] = useState(false);
  useEffect(() => {
    checkFormCompletion();
    actualizarPrecio();
  }, [selectedSize, acomp, selectedDrink, selectedDip, anotaciones, precioExtras, precioExtra, precioBebida]);

  const actualizarPrecio = () => {
    let precioBase = selectedSize === 'simple' ? item.simple : item.doble;
    setPrice(precioBase);
  };

  const bebidaPrecio = selectedDrink && selectedDrink.precio ? selectedDrink.precio : 0;
  console.log("precioo de bebida"+ bebidaPrecio);


// Función para obtener el estado del módulo de bebidas
const obtenerEstadoModuloBebida = async () => {
  try {
      const response = await axios.get('https://pedir-pedir.p89nps.easypanel.host/phone');
      if (response.data.length > 0) {
          setModuloBebida(response.data[0].modulo_bebida === 1);
          console.log("Modulo de bebida itemDetail:"+moduloBebida)
      } else {
          setModuloBebida(false);
      }
  } catch (error) {
      console.error("Error al obtener el estado del módulo de bebidas:", error);
  }
};

// Ejecutar la solicitud al montar el componente
useEffect(() => {
  obtenerEstadoModuloBebida();
}, []);

// Resetear la bebida seleccionada si el módulo está deshabilitado
    useEffect(() => {
        if (!moduloBebida) {
            setSelectedDrink("");  // Limpiar bebida seleccionada
        }
    }, [moduloBebida]);

    const checkFormCompletion = () => {
      const isFormComplete =
        selectedSize !== '' &&
        acomp !== '' &&
        selectedDip !== '' &&
        (moduloBebida ? selectedDrink !== '' : true);  // Validar bebida solo si el módulo está habilitado
    
      setFormComplete(isFormComplete);
    };
    

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleAcompChange = (event) => {
    setAcomp(event.target.value);
  };

  const handleMedallonesExtraChange = (value) => {
    setMedallonesExtras(value);
    console.log("Precio extra"+precioExtra.extraPrice); 
    const precioMedallonesExtra = value * precioExtra.extraPrice; // Ajusta según tu lógica de precios
    setPrecioExtras(precioMedallonesExtra);
  };

  const onAdd = (quantity) => {
    setGoToCart(true);
    addProduct(item, quantity, selectedSize, price, acomp, selectedDrink, selectedDip, anotaciones , { medallones: medallonesExtras });
  };

  return (
    <Container className="mt-4 back-checkout ">
      {/* Imagen centrada */}
      <Row className="justify-content-center mb-4">
        <Col xs={12} className="text-center">
        <h5 className='card-title'>{item.name}</h5>
        <br></br>
          <img className='img-fluid' src={item.image} alt={item.name} style={{ maxWidth: '300px', borderRadius:'15px' }} />
        </Col>
      </Row>

      {/* Nombre y descripción */}
      <Row className="justify-content-center text-center mb-4">
        <Col xs={12}>
          <h5 className='card-title'>Descripcion:</h5>
          <p >{item.description}</p>
        </Col>
      </Row>

      {/* Opciones organizadas en columnas */}
      <Row className="g-1 mb-3 justify-content-center text-center">
        <Col xs={12} md={6}>
          <b className='underline'>Tamaño</b>
          <br />
          <label>
            <input
              type='radio'
              value="simple"
              checked={selectedSize === 'simple'}
              onChange={handleSizeChange}
            />
            Simple
          </label>
          <label className="ml-2">
            <input
              type='radio'
              value="doble"
              checked={selectedSize === 'doble'}
              onChange={handleSizeChange}
            />
            Doble
          </label>
        </Col>
        <Col xs={12} md={6}>
          <b className='underline'>Acompañamiento</b>
          <br />
          <label>
            <input
              type='radio'
              value="Papas fritas"
              checked={acomp === 'Papas fritas'}
              onChange={handleAcompChange}
            />
            Papas fritas
          </label>
          <label className="ml-2">
            <input
              type='radio'
              value="Aros de cebolla"
              checked={acomp === 'Aros de cebolla'}
              onChange={handleAcompChange}
            />
            Aros de cebolla
          </label>
        </Col>
        
      </Row>

      <Row className="g-1 mb-3 justify-content-center text-center">
        <Col xs={12} md={6}>
          <b className='underline'>Salsa</b>
          <br />
          <Dips />
        </Col>
        <Col xs={12} md={6}>
          <b className='underline'>Bebida</b>
          <br />
 {/*          <DrinksDropdown /> */}
 {moduloBebida ? (
                <DrinksDropdown />
            ) : (
                <p>Este local no ofrece bebidas.</p>  // Mensaje opcional si no hay bebidas
            )}
        </Col>
      </Row>

      <Row className="g-1 mb-3 justify-content-center text-center">
      <Col xs={12} md={6}>
          <b className='underline'>Medallones Extra</b>
          <br />
          <MedallonesDropdown onMedallonesExtraChange={handleMedallonesExtraChange} />
        </Col>
      </Row>

      <Row className="mb-3 justify-content-center text-center">
        <Col xs={12}>
          <b className='underline'>Anotaciones</b>
          <textarea
            className="form-control"
            placeholder='Ingrese anotaciones'
            value={anotaciones}
            onChange={(e) => setAnotacion(e.target.value)}
          />
        </Col>
      </Row>

      {/* Precio y botón de añadir */}
      <Row className="mb-3 text-center">
        <Col xs={12}>
        <h3><b>
  Precio: ${selectedSize === 'simple'
    ? item.simple + precioExtras + bebidaPrecio
    : item.doble + precioExtras + bebidaPrecio}
</b></h3>
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <ItemCount stock={10} initial={1} onAdd={onAdd} disabled={!formComplete} />
          <br />
          {goToCart ? (
            <Link to="/cart">
              <Button variant="primary">Ir al carrito</Button>
            </Link>
          ) : (
            <Link to='/home'>
              <Button variant="secondary">Volver</Button>
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ItemDetail;
