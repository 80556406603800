import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "../../components/Metrics/metric.css";
ChartJS.register(ArcElement, Tooltip, Legend);

const ClientMetrics = () => {
    const [range, setRange] = useState('7');
    const [branch, setBranch] = useState('1');
    const [metrics, setMetrics] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null); // Índice del segmento actualmente en hover

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await axios.get(
                    `https://pedir-pedir.p89nps.easypanel.host/metrics/topclients?range=${range}&branch=${branch}`
                );
                setMetrics(response.data);
            } catch (error) {
                console.error('Error fetching client metrics:', error);
            }
        };

        fetchMetrics();
    }, [range, branch]);

    const clientColors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5733',
        '#FF1493', '#800080', '#FFD700', '#00BFFF', '#8A2BE2'
    ];

    const data = metrics
        ? {
              labels: metrics.map((metric) => `Cliente: ${metric.phone}`),
              datasets: [
                  {
                      data: metrics.map((metric) => metric.totalOrders),
                      backgroundColor: clientColors.slice(0, metrics.length),
                  },
              ],
          }
        : null;

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        onHover: (event, chartElement) => {
            if (chartElement.length > 0) {
                setHoverIndex(chartElement[0].index); // Establece el índice del segmento en hover
            } else {
                setHoverIndex(null); // Restablece si no hay hover
            }
        },
    };

    return (
        <div>
            <h2>Métricas de Clientes (Top 10)</h2>
            <select value={range} onChange={(e) => setRange(e.target.value)}>
                <option value="today">Hoy</option>
                <option value="7">Últimos 7 días</option>
                <option value="30">Últimos 30 días</option>
                <option value="180">Últimos 6 meses</option>
                <option value="365">Último año</option>
            </select>
            <select value={branch} onChange={(e) => setBranch(e.target.value)}>
                <option value="1">Sucursal 1</option>
                <option value="2">Sucursal 2</option>
            </select>
            {metrics ? (
                <div className="chart-container">
                    <div className="chart">
            <Doughnut data={data} options={options} />
        </div>                    <div className="legend">
                        {metrics.map((metric, index) => (
                            <div
                                key={index}
                                className={`legend-item ${
                                    hoverIndex === index ? 'active' : ''
                                }`}
                                style={{ borderColor: clientColors[index] }}
                            >
                                <strong>Cliente: {metric.phone}</strong> - Pedidos: {metric.totalOrders}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <p>Cargando métricas de clientes...</p>
            )}
        </div>
    );
};

export default ClientMetrics;
