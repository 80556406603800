import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "../../components/Metrics/metric.css";
// Registra los elementos necesarios para el gráfico Doughnut
ChartJS.register(ArcElement, Tooltip, Legend);

const ConversionMetrics = () => {
    const [range, setRange] = useState('7');
    const [sucursal, setSucursal] = useState('1'); // Valor inicial de sucursal
    const [metrics, setMetrics] = useState(null);

    useEffect(() => {
        const fetchMetrics = () => {
            axios.get(
                `https://pedir-pedir.p89nps.easypanel.host/metrics/conversion?range=${range}&sucursal=${sucursal}`
            )
            .then((response) => {
                console.log('Datos recibidos:', response.data);
                setMetrics(response.data);
            })
            .catch((error) => {
                console.error('Error fetching metrics:', error);
            });
        };
        console.log('Datos de métricas:', metrics);

        fetchMetrics();
    }, [range, sucursal]);

 // Calcular el total de todas las órdenes
 const totalOrders = metrics
 ? metrics.reduce((acc, curr) => acc + curr.total, 0)
 : 0;

// Calcular el total de finalizadas
const totalFinalizadas = metrics
 ? metrics.filter((metric) => metric.estado === 'Finalizado').reduce((acc, curr) => acc + curr.total, 0)
 : 0;

// Calcular la tasa de conversión en porcentaje
const conversionRate = totalOrders ? (totalFinalizadas / totalOrders) * 100 : 0;

const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                // Personaliza la etiqueta de cada ítem en la leyenda
                generateLabels: function (chart) {
                    // Obtén los datos del gráfico
                    const data = chart.data;
                    return data.labels.map((label, index) => {
                        // Calcula el total y porcentaje para cada estado
                        const total = data.datasets[0].data[index] * (totalOrders / 100); // Revertir el porcentaje para obtener el total
                        const percentage = totalOrders ? (total / totalOrders) * 100 : 0;
                        // Devuelve la etiqueta personalizada con total y porcentaje
                        return {
                            text: `${label}: ${total.toFixed(0)} (${percentage.toFixed(2)}%)`, // Muestra el total y el porcentaje
                            fillStyle: data.datasets[0].backgroundColor[index],
                        };
                    });
                },
            },
        },
    },
    responsive: true,
    maintainAspectRatio: false,
};


 // Calcular los porcentajes para cada estado
 const data = metrics
 ? {
    labels: metrics.map((metric) => metric.estado || 'Desconocido'), // Asignar 'Desconocido' si estado es undefined
    datasets: [
           {
               data: metrics.map((metric) => (totalOrders ? (metric.total / totalOrders) * 100 : 0)), // Calcula el porcentaje
               backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#FF5733', '#4CAF50', '#FF1493'],
               hoverBackgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#FF5733', '#4CAF50', '#FF1493'],
           },
       ],
   }
 : null;

    return (
        <div>
            <h2>Métricas de Conversión</h2>
            <select value={range} onChange={(e) => setRange(e.target.value)}>
                <option value="today">Hoy</option>
                <option value="7">Últimos 7 días</option>
                <option value="30">Últimos 30 días</option>
                <option value="180">Últimos 6 meses</option>
                <option value="365">Último año</option>
            </select>
            <select value={sucursal} onChange={(e) => setSucursal(e.target.value)}>
                <option value="1">Sucursal 1</option>
                <option value="2">Sucursal 2</option>
            </select>
            {metrics ? (
                <div className='chart-container'>
                    <h6><strong>Total de ordenes: </strong> {totalOrders} </h6>
                    <p><strong>Tasa de Conversión (Finalizadas: {totalFinalizadas}):</strong> {conversionRate.toFixed(2)}%</p>

<div className="chart">
            <Doughnut data={data} options={options} />
        </div>
                 </div>
            ) : (
                <p>Cargando métricas...</p>
            )}
        </div>
    );
};

export default ConversionMetrics;
