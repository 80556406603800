import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "../../components/Metrics/metric.css";
// Registrar elementos necesarios para el gráfico Doughnut
ChartJS.register(ArcElement, Tooltip, Legend);

const ComboMetrics = () => {
    const [range, setRange] = useState('7'); // Rango de tiempo
    const [branch, setBranch] = useState('1'); // Sucursal seleccionada
    const [metrics, setMetrics] = useState(null);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await axios.get(
                    `https://pedir-pedir.p89nps.easypanel.host/metrics/combos?range=${range}&branch=${branch}`
                );
                console.log('Datos recibidos (Combos):', response.data);
                setMetrics(response.data);
            } catch (error) {
                console.error('Error al obtener métricas de combos:', error);
            }
        };

        fetchMetrics();
    }, [range, branch]); // Recalcula cuando cambian el rango o la sucursal

    // Asignar colores dinámicos para los combos
    const comboColors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5733', '#FF1493', '#800080', '#FFD700'
    ];

    // Configuración de datos para el gráfico
    const data = metrics
        ? {
              labels: metrics.map((metric) => metric.name), // Etiquetas (nombres de combos)
              datasets: [
                  {
                      data: metrics.map((metric) => metric.total), // Datos (totales)
                      backgroundColor: comboColors.slice(0, metrics.length), // Colores
                      hoverBackgroundColor: comboColors.slice(0, metrics.length).map((color) => `${color}CC`),
                  },
              ],
          }
        : null;

    // Opciones del gráfico
// Opciones del gráfico
const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                generateLabels: (chart) => {
                    // Personalización de las etiquetas de la leyenda
                    const data = chart.data;
                    return data.labels.map((label, index) => {
                        // Mostrar nombre del combo y la cantidad
                        const quantity = data.datasets[0].data[index];
                        return {
                            text: `${label} - ${quantity} vendidos`,
                            fillStyle: data.datasets[0].backgroundColor[index],
                            strokeStyle: data.datasets[0].backgroundColor[index],
                            lineWidth: 1,
                        };
                    });
                },
            },
        },
    },
    responsive: true,
    maintainAspectRatio: false,
};

    return (
        <div>
            <h2>Métricas de Combos</h2>

            {/* Selector de rango */}
            <select value={range} onChange={(e) => setRange(e.target.value)}>
                <option value="today">Hoy</option>
                <option value="7">Últimos 7 días</option>
                <option value="30">Últimos 30 días</option>
                <option value="180">Últimos 6 meses</option>
                <option value="365">Último año</option>
            </select>

            {/* Selector de sucursal */}
            <select value={branch} onChange={(e) => setBranch(e.target.value)}>
                <option value="1">Sucursal 1</option>
                <option value="2">Sucursal 2</option>
            </select>

            {metrics ? (
                <div className="chart-container">
              <div className="chart">
            <Doughnut data={data} options={options} />
        </div>
                </div>
            ) : (
                <p>Cargando métricas de combos...</p>
            )}
        </div>
    );
};

export default ComboMetrics;
