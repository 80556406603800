//Permitir agregar redes sociales
//Poder cargar logo (evaluar)
//Optimizar carrusel de promociones (Agregar para modificar el nombre que se visualiza)
//Hacer validaciones de todos los campos.
//Agregar efectos de loading a botones de ordenes
// Habilitar poder realizar pedido a partir de hora de apertura, y dejar de realizar en hora de cierre, agregar contador
// cosas por agregar: acompañmientos;
//Agregar acompañamiento por defecto. 
//Optimizar codigo en cuando a renderizados
// Ver de agregar para modificar nombre de sucursal?? 
//Agregar tooltips en toda la app para mejor experiencia
//Mejorar visual e interfaz  
//Definir sucursales por usuario (Mejora en navegacion de multiples sucursales)
//Optimizar solicitudes a servidor
//Agregar auth.js 
//Ver de optimizar solicitudes a la api reduciendo consumo.
//Definir ttipo de usuario 0,1 y 2, para que solo visualice lo de esa sucursal (0 seria admin)
//- acompañamientos (Hoy esta puesto por defecto, pero se deberia poder modificar)
 // -sucursal: Poder modificar el nombre
  
//Mejorar como se crea el carrusel de promociones: Que se haga un desplegable con todos los productos, y se seleccione en promo o no (hoy se hace de manera individual) y ademas, a ese carrusel especifoco, poder asignarle un dia de la semana y la promocion (Ver cuales hay disponibles) (para poder crear varios y automatizar)

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ItemDetail from '../ItemDetail/ItemDetail';
import "../Item/item.css";

const Item = ({ item }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div className="card" onClick={handleShow} style={{ cursor: 'pointer', position: 'relative' }}>
        {/* Banda cruzada para promociones */}
        {item.promocion === 'si' && (
          <div className="promo-badge">En Promo!</div>
        )}
        <img
          className="card-img-top"
          src={item.image}
          alt="Productos de la web"
          style={{ height: '250px', width: '250px', margin: '0 auto' }}
        />
        <div className="card-body">
          <h5 className="card-title">- Combo: {item.name}</h5>
          <p><b>Clickea para Ampliar info!</b></p>
        </div>
      </div>

      {/* Modal para mostrar ItemDetail */}
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Detalle del Combo</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <ItemDetail item={item} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Item;
