import React, { useState } from 'react';
import axios from 'axios';
import ManualOrder from './ManualOrder';
import ImageUploader from './ImageUploader';
import "../Checkout/checkout.css";
import { Button, Spinner, Toast } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import "../Login/CollectionEditor.css";

const CollectionList = () => {
    const [nombre, setNombre] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [precioSimple, setPrecioSimple] = useState("");
    const [precioDoble, setPrecioDoble] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState(null);
    const [enPromocion, setEnPromocion] = useState(false); // Estado para la promoción

    const agregarCombo = async () => {
        setLoading(true);
        setError(null);

        try {
            await axios.post('https://pedir-pedir.p89nps.easypanel.host/api/productos', {
                categoryId: "combos",
                name: nombre,
                description: descripcion,
                size: "simple",
                size1: "doble",
                simple: precioSimple,
                doble: precioDoble,
                acompañamiento: "Papas fritas",
                acompañamiento1: "Aros de cebolla",
                image: imageUrl,
                promocion: enPromocion ? "si" : "no" // Agregar si o no según el checkbox
            });
            console.log("Combo agregado correctamente");

            // Limpiar los campos de formulario
            setNombre("");
            setDescripcion("");
            setPrecioSimple("");
            setPrecioDoble("");
            setImageUrl("");
            setEnPromocion(false); // Restablecer el checkbox
            setShowToast(true);
        } catch (error) {
            console.error("Error al agregar combo:", error);
            setError("Error al agregar combo. Intente nuevamente.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4>Agregar nuevo combo</h4></Accordion.Header>
                    <Accordion.Body>
                        <label>Nombre combo:</label>
                        <br />
                        <input
                            placeholder='Ingrese nombre del combo'
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                        <br /><br />
                        <ImageUploader onUploadComplete={setImageUrl} />
                        <br /><br />
                        <label>Descripción del combo:</label>
                        <br />
                        <textarea 
                            placeholder='Ingrese descripción del combo' 
                            value={descripcion} 
                            onChange={(e) => setDescripcion(e.target.value)} 
                        />
                        <br /><br />
                        <label>Precio simple:</label>
                        <br />
                        <input
                            placeholder='Ingrese Precio de combo simple'
                            value={precioSimple}
                            onChange={(e) => setPrecioSimple(e.target.value)}
                        />
                        <br /><br />
                        <label>Precio doble:</label>
                        <br />
                        <input
                            placeholder='Ingrese Precio de combo doble'
                            value={precioDoble}
                            onChange={(e) => setPrecioDoble(e.target.value)}
                        />
                        <br /><br />
                        <label>¿En promoción?</label>
                        <input 
                            type='checkbox' 
                            name='promocion' 
                            checked={enPromocion} 
                            onChange={(e) => setEnPromocion(e.target.checked)} 
                        />
                        <br /><br />
                        <Button className="btn-inicio" variant="success" onClick={agregarCombo} disabled={loading}>
                            {loading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}Cargando...
                                </>
                            ) : (
                                'Agregar combo'
                            )}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4>Modificar/eliminar combo:</h4></Accordion.Header>
                    <Accordion.Body>
                        <ManualOrder />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            {/* Mostrar Toast de éxito */}
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Notificación</strong>
                    <small>Justo ahora</small>
                </Toast.Header>
                <Toast.Body>Combo agregado correctamente.</Toast.Body>
            </Toast>

            {/* Mostrar error si existe */}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
    );
};

export default CollectionList;
